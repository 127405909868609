import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { TitlequoteConfigService } from '../../application-config.service';
import { Account } from '../model/account.model';
import { AccountService } from './account.service';
//import { Login } from 'app/login/login.model';

@Injectable({ providedIn: 'root' })
export class AuthServerProvider {
  constructor(private authService: AccountService, private http: HttpClient, private applicationConfigService: TitlequoteConfigService) {}

  loginUser(username: string,  password: string): Observable<Account | null> {
    return this.login(username,password).pipe(mergeMap(() => 
    this.authService.identity(true)));
  }

  login(username: string,  password: string) : Observable<any>{
    var data = 'j_username=' + encodeURIComponent(username) +
    '&j_password=' + encodeURIComponent(password) +
     '&submit=Login';

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post(this.applicationConfigService.getEndpointFor('api/authentication'), data, { headers });
  }

  logout(): Observable<void> {
    // logout from the server
    return this.http.post(this.applicationConfigService.getEndpointFor('api/logout'), {}).pipe(
      map(() => {
        // to get a new csrf token call the api
        this.http.get(this.applicationConfigService.getEndpointFor('api/account')).subscribe();
      })
    );
  }
}

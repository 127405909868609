import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, of } from 'rxjs';
import { shareReplay, tap, catchError, map } from 'rxjs/operators';
import { StateStorageService } from '../userprofile/state-storage.service';
import { Account, ProfileInfo } from '../model/account.model';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private userIdentity: Account | null = null;
  private authenticationState = new ReplaySubject<Account | null>(1);
  private accountCache$?: Observable<Account> | null;


  private profileInfo$?: Observable<ProfileInfo>;

  constructor(
    private http: HttpClient,private stateStorageService: StateStorageService, private router: Router ) {}

  updateAccount(account: Account): Observable<{}> {
    return this.http.post('api/account', account)
    .pipe();
  }

  authenticate(identity: Account | null): void {
    this.userIdentity = identity;
    this.authenticationState.next(this.userIdentity);
    if (!identity) {
      this.accountCache$ = null;
    }
  }

  hasAnyAuthority(authorities: string[] | string): boolean {
    if (!this.userIdentity) {
      return false;
    }
    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }
    return this.userIdentity.authorities.some((authority: string) => {
      return authorities[0].includes(authority);
    });
  }

  identity(force?: boolean): Observable<Account | null> {
    if (!this.accountCache$ || force) {
      this.accountCache$ = this.fetch().pipe(
        tap((account: Account) => {
          this.authenticate(account);

          this.navigateToStoredUrl();
        }),
        shareReplay()
      );
    }
    return this.accountCache$.pipe(catchError(() => of(null)));
  }

  isAuthenticated(): boolean {
    return this.userIdentity !== null;
  }

  getAuthenticationState(): Observable<Account | null> {
    return this.authenticationState.asObservable();
  }

  private fetch(): Observable<Account> {
    return this.http.get<Account>('api/account');
  }

  private navigateToStoredUrl(): void {
    // previousState can be set in the authExpiredInterceptor and in the userRouteAccessService
    // if login is successful, go to stored previousState and clear previousState
    const previousUrl = this.stateStorageService.getUrl();
    if (previousUrl) {
      this.stateStorageService.clearUrl();
      this.router.navigateByUrl(previousUrl);
    }
  }

  getProfileInfo(){
    if (this.profileInfo$) {
      return this.profileInfo$;
    }
    this.profileInfo$ =  this.http.get<ProfileInfo>('api/profile-info',{} )
    .pipe(
      shareReplay()
    )
    return this.profileInfo$;
  }



  resetPasswordInit (mail: string) {
    return this.http.post('api/account/reset_password/init', mail, {} ).pipe(
        map((response: unknown) => {
          console.log("loginUser response :"+response)
          return response;
        })
    );
  }

  resetPasswordFinish (key: String, password: string ) {
    const body = {key: key, newPassword: password};
    return this.http.post('api/account/reset_password/finish', body , {} ).pipe(
        map((response: unknown) => {
        console.log("loginUser response :"+response)
        return response;
        })
    );
  }
    private redirectedToLogin: string = "init";
    getRedirectedToLogin(): string {
      return this.redirectedToLogin;
    }

    setRedirectedToLogin(value : string) {
       this.redirectedToLogin = value;
    }
}

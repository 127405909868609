import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthServerProvider } from '../../service/userprofile/auth-session.service';
import { StateStorageService } from '../../service/userprofile/state-storage.service';
import { AccountService } from '../../service/userprofile/account.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialog } from '../login/login.dialog';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthServerProvider,
    private stateStorageService: StateStorageService,
    private router: Router,
    private dialog: MatDialog,
    private accountService: AccountService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          //if (err.status === 401 && err.url && !err.url.includes('api/account') && this.accountService.isAuthenticated()) {
//           if (err.status === 401 && !this.accountService.isRedirectedToLogin()) {
//             this.accountService.setRedirectedToLogin(true);
//             this.stateStorageService.storeUrl(this.router.routerState.snapshot.url);
//             //this.authService.logout();
//             this.router.navigate(['/login']);
//           }
            if (err.status === 401 && (this.accountService.getRedirectedToLogin() === 'init' )) {
                this.accountService.setRedirectedToLogin('false');
                this.router.navigate(['/login']);
            }else if(err.status === 401 && this.accountService.getRedirectedToLogin() === 'true'){
                this.accountService.setRedirectedToLogin('false');
                this.router.navigate(['/login']);
            }else if (err.status === 403 ) {
              this.router.navigate(['/login']);
            }
        },
      })
    );
  }



  // function getCSRF() {
  //   var doc = this.document[0];
  //   if (doc) {
  //       var name = 'CSRF-TOKEN=';
  //       var ca = doc.cookie.split(';');
  //       for (var i = 0; i < ca.length; i++) {
  //           var c = ca[i];
  //           while (c.charAt(0) === ' ') { c = c.substring(1); }

  //           if (c.indexOf(name) !== -1) {
  //               return c.substring(name.length, c.length);
  //           }
  //       }
  //   }
  //   return '';
  // }

  // function afterCSRFRenewed(oldResponse) {
  //   if (getCSRF() !== '') {
  //       // retry the old request after the new CSRF-TOKEN is obtained
  //       var $http = $injector.get('$http');
  //       return $http(oldResponse.config);
  //   } else {
  //       // unlikely get here but reject with the old response any way and avoid infinite loop
  //       return $q.reject(oldResponse);
  //   }
  // }
}
